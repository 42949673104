@import "../../themes.scss";

.crop-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-body;
    color: $primary-color;
    font-weight: bold;
  }

  .close-icon {
    color: red;
  }

  .dailog-container {
    position: relative;
    height: 400px;
    width: 100%;

    .image-container {
      height: 400px;
      width: 100%;

      .frame-image {
        top: 0;
        left: 90px;
        position: absolute;
        width: 400px;
        height: 400px;
      }

      .avatar-image {
        // position: absolute;
        
        width: 400px;
        height: 400px;
        object-fit: cover;
      }
    }
  }
}
