.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: GothicBoldRegular;
  src: url("./Assets/font/GothicBoldRegular.ttf");
}

@font-face {
  font-family: KhmerOsMuol;
  src: url("./Assets/font/KhmerOSmuollight.ttf");
}

@font-face {
  font-family: Siemreap;
  src: url("./Assets/font/KhmerOSsiemreap.ttf");
}

@font-face {
  font-family: Bayon;
  src: url("./Assets/font/Bayon.ttf");
}

/* ======================================== report style ===================================== */
.setup-report-container {
  padding-top: 20px;
  text-align: center;
  font-size: 50px;
  font-family: Bayon !important;
  font-weight: bold;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-company {
  width: 70px;
  /* height: 150px; */
}

.report-top-title {
  text-align: center !important;
  font-size: 12px !important;
  color: #000 !important;
  font-family: Siemreap !important;
}

.report-container {
  width: 100%;
  padding: 10px 20px;
}

.report-header-row {
  background-color: #0f80c2 !important;
  padding: 6px 20px !important;
  /* border: 1px solid #0F80C2 !important; */
  border: none !important;
  border-radius: 6px !important;
}

.report-header-title-start {
  background-color: #0f80c2 !important;
  border-radius: 6px 0px 0px 6px !important;
  padding: 8px 0px 8px 20px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;
  /* border-top: 1px solid #0F80C2 !important;
  border-bottom: 1px solid #0F80C2 !important; */
  border: none !important;
}

.report-header-title {
  background-color: #0f80c2 !important;
  padding: 8px 0px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;
  /* border: 1px solid #0F80C2 !important; */
  border: none !important;
  text-align: left;
}

.report-header-title-end {
  background-color: #0f80c2 !important;
  border-radius: 0px 6px 6px 0px !important;
  padding: 8px 20px 8px 0px !important;
  font-size: 12px !important;
  font-family: Siemreap !important;
  color: #fff !important;

  /* border-top: 1px solid #0F80C2 !important;
  border-bottom: 1px solid #0F80C2 !important; */
  border: none !important;
}
.report-body-cell-bottom {
  color: #000 !important;
  font-weight: bold !important;
  border: none !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
  padding: 8px 0px !important;
}
.report-body-cell-bottom-start {
  color: #0f80c2 !important;
  border: none !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
  padding: 8px 0px !important;
}
.report-body-cell-start {
  padding: 8px 0px 8px 20px !important;
  font-size: 11px !important;
  font-family: Siemreap !important;
}
.report-body-cell {
  font-family: Siemreap !important;
  padding: 8px 0px !important;
  font-size: 11px !important;
}
.report-body-cell-end {
  font-family: Siemreap !important;
  font-size: 11px !important;
  padding: 8px 0px !important;
}
.report-body-row {
  padding: 6px 20px !important;
}
