// -------------------Color------------------------
$primary-color: #0f81c2;
// $primary-color: #0F80C2;

$primary-background: #eef0f3;
$primary-menu: #c7cacf;
$background-delete: #f75257;
$background-dollar: #ff8204;
$gray-font-color: #484949;

$font-bayon: "KhmerOsMuol" !important;
$font-title: "Century Gothic";
$font-body: "Siemreap" !important;
$font-moul: "KhmerOsMuol";

//font-size
$font-size-title: 18px;
$font-size-subtitle: 16px;
$font-size-body: 15px;
// Login Page

@mixin desktop {
  @media (min-width: #{1025px}) and (max-width: #{1650px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{769px}) and (max-width: #{1024px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{426px}) and (max-width: #{768px}) {
    @content;
  }
}

@mixin mobile-large {
  @media (min-width: #{426px}) and (max-width: #{600px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{377px}) and (max-width: #{425px}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width : #{0px}) and (max-width: #{376px}) {
    @content;
  }
}
