@import "../themes.scss";

.page-container {
  height: 100vh;

  .btn-container {

    .image-container {    
      position: relative;
      // background-color: red;
      width: 400px;
      height: 410px;

      .frame-image {    
        position: absolute;
        width: 400px;
        height: 410px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
      }

      .avatar-image {
        margin: 0px 0px 0px 0px !important;
        width: 400px;
        height: 410px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .image-container-download-preview {
    position: relative;
    width: 500px;
    height: 510px;

    .frame-image-download-preview {
      top: 0;
      left: 0;
      position: absolute;
      width: 500px;
      height: 510px;
    }

    .avatar-image-download-preview {
      margin: 0px 0px 0px 0px !important;
      width: 500px;
      height: 510px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .btn-download {
    width: 250px;
    background-color: $primary-color !important;
    color: #fff;
  }
  
}
